
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import variables from "@/router/api";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    const registration = Yup.object().shape({
      firstName: Yup.string()
        .required()
        .label("First Name"),
      lastName: Yup.string()
        .required()
        .label("Last Name"),
      emailAddress: Yup.string()
        .min(4)
        .required()
        .email()
        .label("Email"),
      phoneNumber: Yup.number()
        .min(11)
        .required()
        .label("Phone Number"),
      password: Yup.string()
        .min(8)
        .required()
        .label("Password"),
      cpassword: Yup.string()
        .min(8)
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Confirm Password"),
    });

    const onSubmitRegister = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Send registration request
      ApiService.post(variables.REGISTER_ROUTE, values)
        .then((result) => {
          variables.toastAlert(result.data.message, "success");
          setTimeout(() => {
            router.push({
              name: "verify-email",
              params: {
                firstName: result.data.user.firstName,
                lastName: result.data.user.lastName,
                email: result.data.user.email,
              },
            });
          }, 2000);
        })
        .catch(({ response }) => {
          variables.toastAlert(response.data.error, "error");
        });

      // Dummy delay
      setTimeout(() => {
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
    };
  },
  created() {
    document.title = "Sign Up | " + process.env.VUE_APP_TITLE;
  },
});
